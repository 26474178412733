import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username: string = '';
  public password: string = '';

  constructor(private  authService: AuthService) {
  }

  ngOnInit() {
  }

  public isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  public logout() {
    this.authService.logout();
  }

  onSubmit() {
    this.authService.login(this.username, this.password);
  }
}
