export function shuffle(array: any[]) {
  var ctr = array.length, temp, index;

// While there are elements in the array
  while (ctr > 0) {
// Pick a random index
    index = Math.floor(Math.random() * ctr);
// Decrease ctr by 1
    ctr--;
// And swap the last element with it
    temp = array[ctr];
    array[ctr] = array[index];
    array[index] = temp;
  }
  return array;
}

export function includes(array, value) {
  const pos = array.indexOf(value);
  return pos >= 0;
}

export function random(min, max): number {
  return (Math.random() * (max - min) + min);
}
