import Vote, {VoteDto} from './vote';
import {SubmissionFirestoreService} from '../services/submission-firestore.service';
import {map, switchMap} from 'rxjs/operators';
import {Image, IMAGE_CATEGORY} from './image';
import {combineLatest, Observable, of} from 'rxjs';
import {Rating} from './rating';
import SubmissionList from './submissionList';
import ICSVRow from './ICSVRow';
import {ImageFirestoreService} from '../services/image-firestore.service';
import {config} from '../../../environments/config';
import {shuffle} from '../../../utils/functions';
import {Round} from './round';
import {StatisticFirestoreService} from '../services/statistic-firestore.service';


export interface SubmissionDto {
  key?: string;
  age: number;
  categoryOrder: number[];
  gender: string;
  referrer: string;
  client: string;
  votes: VoteDto[];
}

export class Submission implements ICSVRow {

  private constructor(readonly age: number,
                      readonly gender: string,
                      readonly referrer: string,
                      readonly client: string,
                      public votes: Vote[] = [],
                      public key: string = null,
                      readonly categoryOrder: IMAGE_CATEGORY[] = shuffle(config.categories)) {
    this.age = age ? age : null;
    this.gender = gender ? gender : null;
    this.referrer = referrer ? referrer : null;
    this.client = client ? client : null;
  }

  public get ratedImagesCount(): number {
    return this.votes.length;
  }

  public static of(age: number, gender: string, referrer: string, client: string, votes: Vote[] = [], key: string = null, categoryOrder: IMAGE_CATEGORY[] = shuffle(config.categories)): Submission {
    return new Submission(age, gender, referrer, client, votes, key, categoryOrder);
  }

  public static ofDto(dto: SubmissionDto) {
    return Submission.of(dto.age, dto.gender, dto.referrer, dto.client, Vote.ofDtos(dto.votes), dto.key, dto.categoryOrder);
  }

  public static empty(): Submission {
    return new Submission(null, null, null, config.client, []);
  }

  public static changeRatedImage(imageToReplace: string, newImage: string, submissionFirestoreService: SubmissionFirestoreService): Promise<void> {
    return submissionFirestoreService.findAll().pipe(map((submissions: SubmissionList) => {
      for (let i = 0; i < submissions.size(); i++) {
        const sub = submissions.get(i);
        if (!sub.hasRatingForImage(imageToReplace)) {
          continue;
        }
        for (let index = 0; index < sub.votes.length; index++) {
          sub.votes[index].changeRatedImage(imageToReplace, newImage);
        }
        sub.save(submissionFirestoreService);
      }
      console.log('all submissions updated');
    })).toPromise();
  }

  public toDto(): SubmissionDto {
    return {
      age: this.age,
      gender: this.gender,
      referrer: this.referrer,
      client: this.client,
      votes: Vote.toDtos(this.votes),
      categoryOrder: this.categoryOrder
    };
  }

  public toCSVArray(): string[] {
    return [this.key, this.gender, this.age ? this.age.toString() : null, this.referrer];
  }

  public toRatings(): Rating[] {
    return this.votes.map(vote => vote.toRating(this.key));
  }

  private completedRounds(): number {
    return Math.floor(this.votes.length / (config.imagesPerCategory * config.categories.length));
  }

  private nrRatedImagesPerCategory(category: IMAGE_CATEGORY): number {
    return this.votes.filter(vote => vote.imageCategory === category).length;
  }

  public hasVotedImage(image: Image): boolean {
    return Vote.existsVoteFor(image, this.votes);
  }

  public unratedImages(imageFirestoreService: ImageFirestoreService): Observable<Image[]> {
    return imageFirestoreService.findAllVisible().pipe(
      map((images: Image[]) => images.filter(image => !this.hasVotedImage(image))),
      map((images: Image[]) => Image.orderByOrderKey(images)));
  }

  public imageChunkForCategory(nrOfImagesToLoad: number, imageFirestoreService: ImageFirestoreService, statisticFirestoreService: StatisticFirestoreService, category: IMAGE_CATEGORY): Observable<Image[]> {
    return this.loadVisibleImages(category, this.nrRatedImagesPerCategory(category), nrOfImagesToLoad, imageFirestoreService);
  }

  /**
   * Loads visible images from firestore. To reduce requests it iteratively loads new chunk of images until enough unrated images returned
   * Example:
   * - First Request => startAt: 0 , limit: 2 (Only 1 of these has not been rated yet)
   * - Second Request => startAt: 2 , limit: 2 (Merges response of first request with response of second request)
   * - ... continue until 2 (@nrImagesToLoad Param) unrated images exists
   */
  private loadVisibleImages(category: IMAGE_CATEGORY, startAt: number, nrImagesToLoad: number, imageFirestoreService: ImageFirestoreService, alreadyLoadedImages: number = 0): Observable<Image[]> {
    return imageFirestoreService.findVisibleImages(category, startAt, nrImagesToLoad).pipe(
      switchMap((imgs: Image[]) => {
        if (imgs.length === 0) { // indicates that there are no further images available in the firestore (to prevent endless loop))
          return of(imgs);
        }
        const lastSeenOrderIndex = Math.max(...imgs.map(img => img.orderIndex));
        return of(imgs).pipe(
          map((images: Image[]) => images.filter(image => !this.hasVotedImage(image))),
          map((images: Image[]) => Image.orderByOrderKey(images)),
          switchMap((images: Image[]) => {
            if ((images.length + alreadyLoadedImages) < nrImagesToLoad) {
              return this.loadVisibleImages(category, Math.max(lastSeenOrderIndex+1, startAt + nrImagesToLoad), nrImagesToLoad, imageFirestoreService, alreadyLoadedImages + images.length).pipe(
                map((newImages: Image[]) => {
                  console.log(IMAGE_CATEGORY[category], 'startat', startAt, 'first', images.map(img => img.key), 'second', newImages.map(img => img.key));
                  return [...images, ...newImages.splice(0, Math.min(newImages.length, nrImagesToLoad - images.length))] as Image[]; // concat old and new image list
                })
              );
            }
            return of(images);
          }));
      }),
    );
  }

  public get nrOfMultipleVotedImageUrls(): number {
    return this.multipleVotedImageUrls().length;
  }

  public multipleVotedImageUrls(): string[] {
    return Vote.multipleVotedImageUrls(this.votes);
  }

  public addVote(vote: Vote): void {
    this.votes.push(vote);
  }

  public save(submissionFirestoreService: SubmissionFirestoreService): Promise<string> {
    return submissionFirestoreService.save(this)
      .then(id => {
        this.key = id;
        return id;
      });
  }

  public hasAge(age: number): boolean {
    return this.age === age;
  }

  public remove(submissionService: SubmissionFirestoreService) {
    submissionService.remove(this.key);
  }

  /**
   * @returns {boolean} if something has changed
   */
  public deleteVotesFor(imageKey: string): boolean {
    const tmpVotes = this.votes.length;
    this.votes = this.votes.filter(vote => !vote.forImage(imageKey));
    const nrOfDeleted = (tmpVotes - this.votes.length);
    if (nrOfDeleted > 0) {
      console.log('deleted ', nrOfDeleted, 'votes for submission ', this.key);
    }
    return nrOfDeleted > 0;
  }

  public nextRound(imageFirestoreService: ImageFirestoreService, statisticFirestoreService: StatisticFirestoreService): Observable<Round> {
    const imagesByCategory: Observable<Image[]>[] = this.categoryOrder.map(category => {
      return this.imageChunkForCategory(config.imagesPerCategory, imageFirestoreService, statisticFirestoreService, category);
    });

    return combineLatest(imagesByCategory).pipe(
      map((imagesList: Image[][]) => [].concat.apply([], imagesList)), // flats multidimensional arrays
      map((images: Image[]) => new Round(images, this.completedRounds() + 1))
    );
  }

  private hasRatingForImage(imageKey: string): boolean {
    return this.votes.some(vote => vote.forImage(imageKey));
  }

  isSurveyCircle() {
    return (this.referrer || '').startsWith('sc');
  }
}
