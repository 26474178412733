import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Statistic} from '../model/statistic';
import {VoteCountersDto, VoteStatistic} from '../../reporting/model/vote-statistic';
import {config} from '../../../environments/config';

@Injectable({
  providedIn: 'root'
})
export class StatisticFirestoreService {

  private readonly TABLE_NAME = environment.statisticsTable;

  private doc: AngularFirestoreDocument<any>;

  public statistic: Statistic;

  constructor(private db: AngularFirestore) {
    this.doc = db.doc(this.TABLE_NAME + '/counters');

  }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.doc.get().subscribe(dto => {
        this.statistic = Statistic.ofDto(dto.data());
        resolve(true);
      });
    });
  }

  loadVoteStatistics(): Observable<VoteStatistic> {
    console.log('load vote statistics from', StatisticFirestoreService.voteStatisticsDocumentName());
    return this.db
      .doc(this.TABLE_NAME + '/' + StatisticFirestoreService.voteStatisticsDocumentName())
      .snapshotChanges()
      .pipe(
        map(documentSnapshot => {
          const voteCounters = documentSnapshot.payload.data() as VoteCountersDto;
          return VoteStatistic.ofDto(voteCounters);
        })
      );
  }

  private static voteStatisticsDocumentName(): string {
    return (config.client ? config.client + '-' : '') + 'vote-counters';
  }
}
