import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable, of} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private afStorage: AngularFireStorage) {
  }

  public saveBlob(key: string, blob: Blob): Promise<void> {
    const ref = this.afStorage.ref(key);
    return ref.put(blob)
      .then(it => console.log('uploaded image', key, it));
  }

  public remove(key: string): Promise<void> {
    return this.deleteFile(key)
      .pipe(
        flatMap(it => {
          console.log('deleted image file', key, 'now trying to delete additional images key_1, key_2 etc.');
          return this.removeAdditionalImage(key, 1);
        })
      ).toPromise();
  }

  public removeAdditionalImage(key: string, index: number): Observable<void> {
    const imageName = key + '_' + index;
    return this.deleteFile(imageName)
      .pipe(
        flatMap(
          _ => {
            console.log('removed additional file', imageName, 'check if further additional images exists');
            return this.removeAdditionalImage(key, ++index);
          }
        ),
        catchError(error => {
          console.log('ERROR IS OK! No further additional images exists.');
          return of<void>();
        }),
      );
  }

  private deleteFile(key: string): Observable<any> {
    return this.afStorage.ref(key).delete();
  }
}

