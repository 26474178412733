import {Submission} from './submission';
import Dataset from './dataset';
import CsvData from './csvData';
import {SubmissionFirestoreService} from '../services/submission-firestore.service';

export default class SubmissionList {
  list: Submission[];

  constructor(submissions: Submission[]) {
    this.list = submissions;
  }

  public static of(submissions: Submission[]): SubmissionList {
    return new SubmissionList(submissions);
  }

  public static ofDtos(dtos: any[]): SubmissionList {
    return this.of(
      dtos.map(dto => Submission.ofDto(dto))
    );
  }

  public static empty(): SubmissionList {
    return this.of([]);
  }

  public isEmpty(): boolean {
    return this.list.length === 0;
  }

  public tail(): Submission[] {
    return this.list.slice(this.list.length - 20, this.list.length);
  }

  public size(): number {
    return this.list.length;
  }

  public avgRatedImages(): number {
    return Math.round(this.list
        .map(sub => sub.votes.length)
        .reduce((a, b) => a + b)
      / this.list.length);
  }

  public toDataset(): Dataset {
    const ratings = this.list
      .map(submission => submission.toRatings())
      .reduce((x, y) => x.concat(y), []);
    return Dataset.create(ratings);
  }

  public get(index: number): Submission {
    return this.list[index];
  }

  public userInfoCsv() {
    return CsvData.of('userinfos.csv', this.list);
  }


  public deleteVotesFor(imageKey: string, submissionService: SubmissionFirestoreService) {
    this.list.forEach(submission => {
      const hasChanged = submission.deleteVotesFor(imageKey);
      if (hasChanged) {
        submission.save(submissionService);
      }
    });
  }
}
