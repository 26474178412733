import {StatisticFirestoreService} from '../../shared/services/statistic-firestore.service';
import {Observable} from 'rxjs';

export interface VoteCountersDto {
  votes: number;
  submissions: number;
  'submissions-by-age': object;
  'submissions-by-gender': object;
  'submissions-by-referrer': object;
  'submissions-by-date': object;
  'votes-by-rating': object;
  'votes-by-category': object;
  'votes-by-date': object;
}

export class VoteStatistic {

  constructor(readonly votes: number,
              readonly submissions: number,
              readonly submissionsByAge: Map<string, number>,
              readonly submissionsByGender: Map<string, number>,
              readonly submissionsByReferrer: Map<string, number>,
              readonly submissionsByDate: Map<string, number>,
              readonly votesByRating: Map<string, number>,
              readonly votesByCategory: Map<string, number>,
              readonly votesByDate: Map<string, number>) {
  }

  public static ofDto(dto: VoteCountersDto): VoteStatistic {
    return new VoteStatistic(dto.votes,
      dto.submissions,
      this.objectToMap(dto['submissions-by-age']),
      this.objectToMap(dto['submissions-by-gender']),
      this.objectToMap(dto['submissions-by-referrer']),
      this.dateObjectToMap(dto['submissions-by-date']),
      this.objectToMap(dto['votes-by-rating']),
      this.objectToMap(dto['votes-by-category']),
      this.dateObjectToMap(dto['votes-by-date']));
  }

  public static load(statisticFirestoreService: StatisticFirestoreService): Observable<VoteStatistic> {
    return statisticFirestoreService.loadVoteStatistics();
  }

  private static objectToMap(obj: object): Map<string, number> {
    if (obj == null) {
      return null;
    }
    return new Map(Object.entries(obj));
  }

  private static dateObjectToMap(obj: object): Map<string, number> {
    if (obj == null) {
      return null;
    }
    return new Map(Object.entries(obj)
      .map(it => [new Date(it[0]), it[1]])
      .sort((a, b) => a[0].getTime() - b[0].getTime())
      .map(it => [(this.dateString(it[0])), it[1]])
    );
  }

  private static dateString(date: Date): string {
    return date.toDateString();
  }
}
