import {IMAGE_CATEGORY} from './image';
import {config} from '../../../environments/config';

export class Statistic {

  constructor(private imageCountersPerCategory: Map<IMAGE_CATEGORY, number>){ }

  public static of(imageCountersPerCategory: Map<IMAGE_CATEGORY, number>): Statistic {
    return new Statistic(imageCountersPerCategory);
  }

  public static ofDto(dto) {
    const imageCountersPerCategory: Map<IMAGE_CATEGORY, number> = new Map();
    config.categories.forEach(category => {
      imageCountersPerCategory.set(category, dto[IMAGE_CATEGORY[category] + '-counter'] || 0);
    })
    return Statistic.of(imageCountersPerCategory);
  }

  public imageCounterPerCategory(category: IMAGE_CATEGORY): number {

    if (this.imageCountersPerCategory.has(category)) {
      return this.imageCountersPerCategory.get(category);
    } else {
      return 0;
    }
  }
}
