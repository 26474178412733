import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {TranslateService} from './translate.service';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/firestore';
import {StatisticFirestoreService} from './shared/services/statistic-firestore.service';

import {SurveyModule} from './survey/survey.module';

export function setupTranslateFactory(
  service: TranslateService): Function {
  let lang = navigator.language;
  let langFileName;
  if (lang.indexOf('de') > -1) {
    langFileName = 'de';
  } else {
    langFileName = 'en';
  }
  return () => service.use(langFileName);
}

export function loadStatisticsFactory(service: StatisticFirestoreService): Function {
  return () => service.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SurveyModule,
    AppRoutingModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    BrowserAnimationsModule
  ],
  providers: [TranslateService, AngularFirestore, StatisticFirestoreService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }, {
      provide: APP_INITIALIZER,
      useFactory: loadStatisticsFactory,
      deps: [StatisticFirestoreService],
      multi: true
    }],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
