import {Rating} from './rating';
import {ImageRating} from './imageRating';
import CsvData from './csvData';

export default class Dataset {

  private readonly ratings: Rating[];

  private constructor(ratings: Rating[]) {
    this.ratings = ratings;
  }

  public static create(ratings: Rating[]): Dataset {
    return new Dataset(ratings);
  }

  public csvData(): CsvData {
    return CsvData.of('ratings.csv', this.ratings);
  }

  public toImageRatings(): ImageRating[] {
    return this.ratings.reduce((previousValue, currentValue) => currentValue.appendImageRatingTo(previousValue), []);
  }
}

