// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {Environment} from './environment.model';

export const environment: Environment = {
  environment: 'local',
  production: false,
  submissionsTable: 'submissions',
  imagesTable: 'images',
  statisticsTable: 'statistics',
  analyticsId: 'UA-146174344-2',
  storageBaseUrl: 'https://storage.googleapis.com/innofind-labeler-test.appspot.com/',
  firebase: {
    apiKey: 'AIzaSyCgxQ7Nmju5YAbwbS_3Hdb49Nw2ierKo4I',
    authDomain: 'innofind-labeler-test.firebaseapp.com',
    databaseURL: 'https://innofind-labeler-test.firebaseio.com',
    projectId: 'innofind-labeler-test',
    storageBucket: 'innofind-labeler-test.appspot.com',
    messagingSenderId: '991722478107',
    appId: '1:991722478107:web:bbbcc1264f49c6188db6be'
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
