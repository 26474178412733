import ICSVRow from './ICSVRow';

export default class CsvData {

  private name: string;
  private data: ICSVRow[];

  private constructor(name: string, data: ICSVRow[]) {
    this.name = name;
    this.data = data;
  }

  public static of(name: string, data: ICSVRow[]) {
    return new CsvData(name, data);
  }

  private toCSVString(): string {
    let csvContent = 'data:text/csv;charset=utf-8,';

    this.data.forEach(data => {
      const row = data.toCSVArray().join(',');
      csvContent += row + '\r\n';
    });

    return csvContent;
  }

  public download() {
    const csvContent = this.toCSVString();
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', this.name);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  }
}
