import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Submission, SubmissionDto} from '../model/submission';
import {first, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import SubmissionList from '../model/submissionList';
import Dataset from '../model/dataset';
import {AngularFirestoreCollection} from '@angular/fire/firestore/collection/collection';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SubmissionFirestoreService {
  private readonly TABLE_NAME = environment.submissionsTable;
  private list: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) {
    this.list = db.collection(this.TABLE_NAME);
  }

  public findAll(): Observable<SubmissionList> {
    return this.list.snapshotChanges().pipe(
      first(),
      map(changes => {
        return changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
      }),
      map((dtos: any[]) => SubmissionList.ofDtos(dtos))
    );
  }

  findFirst(amount: number) {
    return this.db.collection(this.TABLE_NAME, ref =>
      ref.limit(amount)
    ).snapshotChanges()
      .pipe(
      first(),
      map(changes => {
        return changes.map((c: any) => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
      }),
      map((dtos: any[]) => SubmissionList.ofDtos(dtos))
    );
  }

  public find(key: string): Observable<Submission> {
    const submission$ = this.db.doc(this.TABLE_NAME + '/' + key);
    return submission$.snapshotChanges().pipe(
      map((c: any) => ({key: c.payload.id, ...c.payload.data()})),
      map(it => Submission.ofDto(it as SubmissionDto))
    );
  }

  public dataset(): Observable<Dataset> {
    return this.findAll().pipe(
      map((submissions: SubmissionList) => submissions.toDataset())
    );
  }

  public save(submission: Submission): Promise<string> {
    console.log('save submission ', submission.key);
    if (submission.key) {
      return this.update(submission);
    }
    return this.insert(submission);
  }

  private insert(submission: Submission): Promise<string> {

    return this.list.add(submission.toDto())
      .then(ref => ref.id);
  }

  private update(submission: Submission): Promise<string> {
    console.log('update submission on firebase ', submission.key);
    return this.db
      .doc(this.TABLE_NAME + '/' + submission.key)
      .update(submission.toDto())
      .then(_ => submission.key);
  }

  public removeAll() {
    if (this.TABLE_NAME !== 'research-test' && this.TABLE_NAME !== 'load-test') {
      console.error('removeAll is only allowed on research-test table. Current table is ' + this.TABLE_NAME);
      return;
    }
    console.log('remove all submissions on ' + this.TABLE_NAME);
    this.db.doc(this.TABLE_NAME).delete().then(_ => console.log('all submissions removed on ' + this.TABLE_NAME));
  }

  public remove(key: string) {
    this.db.doc(this.TABLE_NAME + '/' + key).delete()
      .then(_ => console.log('submission ' + key + ' deleted'));
  }
}
