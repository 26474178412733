import {Image} from './image';
import {Rating} from './rating';
import {includes, random} from '../../../utils/functions';

export const MINIMUM_NUMBER_OF_RATINGS = 200;

const REDUCE_IF_MORE_THEN_RATINGS = 150;
const REDUCE_IF_AVG_RATING_MORE_THEN = 0.8;
const REDUCE_AVG_BY = [0, 0.5];

const BOOST_CHAIR_AVG_BETWEEN = [0.5, 2];
const METAL_LEG_CHAIRS = [
  '-LNAj21U2UYMJUZmzrYw',
  '-LNAj2258iODnKsnvtpB',
  '-LNAjlIr4gbZhpm_JdxA',
  '-LNAjlLPVRotXlVKY1wJ',
  '-LNAiJlZL5Otj-rR1nGl',
  '-LNAicPUcU8p2SzEb_c8',
  '-LNAiJsN1UFPz3ahj2UN',
  '-LNAiJsar694eRb7AZF_',
  '-LNAnjA1fn_dHXSl6GM9',
  '-LNAiJiXq1beKfPhbf7a',
  '-LNAiJu6BafNfkdwrLP8',
  '-LNAiJtgxvBY9myq_Cpr',
  '-LNAkN_4glsejDeavO5e',
  '-LNAlQlGc2DhLF3wn21Y',
  '-LNAkO3QEnPcCa073A52',
  '-LNAoO79fn5wORK_Hg14',
  '-LNAlQkY-SoUzplS10Ff'];
const BOOST_IMAGE_IDS = [];

export class ImageRating { // TODO do not export class
  private image: string;
  private readonly ratings: number[] = [];
  private _avgRating: number = undefined;

  constructor(item: string, ratings: number[] = []) {
    this.image = item;
    this.ratings = ratings;
  }

  public static randomRatings(image: string): ImageRating {
    const imageRating = new ImageRating(image);
    return new ImageRating(image, imageRating.extendedRatings);
  }

  public static empty(item: string): ImageRating {
    return new ImageRating(item, []);
  }

  public append(rating: number): ImageRating {
    this.ratings.push(rating);
    this._avgRating = undefined;
    return this;
  }

  public forImage(item: string) {
    return this.image === item;
  }

  private boostedAvgRating(): number {
    return random(BOOST_CHAIR_AVG_BETWEEN[0], BOOST_CHAIR_AVG_BETWEEN[1]);
  }

  public get avgRating(): number {
    if (!this._avgRating) {
      this._avgRating = this.calculateAvgRating();
    }
    return this._avgRating;
  }

  private calculateAvgRating(): number {
    if (includes(BOOST_IMAGE_IDS, this.image)) {
      const boostedAvg = this.boostedAvgRating();
      console.log('use boosted avg:', boostedAvg);
      return boostedAvg;
    }

    if (this.ratings.length === 0) {
      return 0;
    }

    const extendedRatings = this.extendedRatings;
    let avg = extendedRatings.reduce((a, b) => a + b) / this.ratings.length;

    if (avg > 2 || avg < 0) {
      console.log('over 2 or under 0');
      console.log('normale avg (', this.image, ') avg=', avg.toFixed(3));
    }

    // reduce avg ratings
    if (this.ratings.length > REDUCE_IF_MORE_THEN_RATINGS && avg > REDUCE_IF_AVG_RATING_MORE_THEN) {
      const reduceBy = random(REDUCE_AVG_BY[0], REDUCE_AVG_BY[1]);
      avg = avg - reduceBy;
      console.log('reduced avg =', avg);
    }
    return avg;
  }

  private get extendedRatings(): number[] {
    const additionalRatings = [];
    const missingRatings = MINIMUM_NUMBER_OF_RATINGS - this.ratings.length;
    for (let i = 0; i < missingRatings; i++) {
      additionalRatings.push(Rating.randomRating());
    }
    return additionalRatings.concat(this.ratings);
  }

  public static find(imageRatings: ImageRating[], search: Image): ImageRating {
    return imageRatings.find(it => it.forImage(search.key)) || ImageRating.randomRatings(search.key);
  }
}
