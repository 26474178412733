import {Image, IMAGE_CATEGORY} from './image';
import {Rating} from './rating';


export interface VoteDto {
  date: string;
  image: string;
  imageCategory: string;
  vote: number;
}

export default class Vote {
  private image: string;
  public imageCategory: IMAGE_CATEGORY;
  readonly vote: number; // 0 = dislike, 1 = default, 2 = like
  private date: Date;

  constructor(image: string, imageCategory: IMAGE_CATEGORY, vote: number, date: Date) {
    this.image = image;
    this.imageCategory = imageCategory === undefined ? null : imageCategory;
    this.vote = vote;
    this.date = date ? date : null;
  }

  public static of(image: string, imageCategory: IMAGE_CATEGORY, vote: number) {
    return new Vote(image, imageCategory, vote, new Date());
  }

  public static ofDtos(dtos: VoteDto[]): Vote[] {
    if (!dtos) {
      return [];
    }
    return dtos.map(dto => this.ofDto(dto));
  }

  static ofDto(dto: VoteDto) {
    return new Vote(dto.image, dto.imageCategory ? IMAGE_CATEGORY[dto.imageCategory.toUpperCase()] : null, dto.vote, dto.date ? new Date(dto.date) : null);
  }

  public static toDtos(votes: Vote[]): VoteDto[] {
    return votes.map(vote => ({
      'image': vote.image,
      'imageCategory': IMAGE_CATEGORY[vote.imageCategory],
      'vote': vote.vote,
      'date': vote.date ? vote.date.toISOString() : null
    }));
  }

  private get timestamp(): number {
    return this.date ? this.date.getTime() : 0;
  }

  public toRating(user: string): Rating {
    return Rating.of(user, this.image, this.vote, this.timestamp);
  }

  public voteDiff(other: Vote): number {
    return Math.abs(this.vote - other.vote);
  }

  public sameImage(other: Vote): boolean {
    return this.image === other.image;
  }

  private isFor(image: Image): boolean {
    return image.hasKey(this.image);
  }

  public forImage(imageKey: string) {
    return this.image === imageKey;
  }

  public static multipleVotedImageUrls(votes: Vote[]) {
    const votesImages = votes.map(vote => vote.image);
    const sorted_arr = votesImages.slice().sort(); // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    const results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    if (results.length > 0) {
      console.log('multiple voted images', results);
    }
    return results;
  }

  public static avgDeviation(votes1: Vote[], votes2: Vote[]): number {
    let sum = 0;
    let count = 0;
    votes1.forEach(vote => {
      const otherVote = this.voteForImage(vote, votes2);
      if (otherVote) {
        sum += vote.voteDiff(otherVote);
        count += 1;
      }
    });
    return Math.round((sum / count) * 1000) / 1000;
  }

  public static existsVoteFor(image: Image, votes: Vote[]): boolean {
    return votes.some(vote => vote.isFor(image));
  }

  public static voteForImage(baseVote: Vote, votes: Vote[]) {
    return votes.filter((vote: Vote) => vote.sameImage(baseVote))[0];
  }

  public changeRatedImage(imageToReplace: string, newImage: string) {
    if (this.image === imageToReplace) {
      console.log('change vote for', this.image, 'to', newImage);
      this.image = newImage;
    }
  }

  existsIn(votes: Vote[]): boolean {
    return votes.some(otherVote => this.equals(otherVote));
  }

  private equals(otherVote: Vote) {
    return this.image === otherVote.image &&
      this.vote === otherVote.vote &&
      this.date.getTime() === otherVote.date.getTime() &&
      this.imageCategory === otherVote.imageCategory;
  }
}
