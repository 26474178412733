import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Image} from '../../shared/model/image';
import {ImageProvider} from '../../shared/model/imageProvider';
import * as Swiper from '../../../../node_modules/swiper/js/swiper';

@Component({
  selector: 'app-survey-image',
  templateUrl: './survey-image.component.html',
  styleUrls: ['./survey-image.component.css']
})
export class SurveyImageComponent implements OnChanges {

  @Input() image: Image;

  @Output() imageReady = new EventEmitter<void>();
  @Output() imageLoadingError = new EventEmitter<void>();

  public imageDomain: string;

  public loading = true;
  public showSwiper = true;

  mySwiper: Swiper;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image && changes.image.previousValue && changes.image.currentValue.url !== changes.image.previousValue.url) {
      this.showSwiper = false;
      setTimeout(_ => {
        this.showSwiper = true;
      }, 1);
    }

    this.imageDomain = this.findImageDomain();
  }

  private initSwiper() {
    this.mySwiper = new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        clickable: true
      },
      keyboard: {
        enabled: true,
       },
      observer: true,
      paginationClickable: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }

  public findImageDomain(): string {
    const imageProvider = ImageProvider.findBySource(this.image.source);
    if (!imageProvider) {
      console.warn('No image Provider defined for image source', this.image.source);
      return '';
    }
    return imageProvider.website;
  }

  imageLoaded() {
    this.loading = false;
    this.initSwiper();
    this.imageReady.emit();
  }

  onImageLoadingError() {
    this.imageLoadingError.emit();
  }
}
