import {Component, OnInit} from '@angular/core';
import {SubmissionKeeper} from '../../shared/services/submission-keeper.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
    public readonly SHOW_USER_INFO: boolean = true;

    public age: number;
    public gender: string;

    private referrer: string;
    public validAge = true;

    constructor(
        private submissionService: SubmissionKeeper,
        private router: Router,
        private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.submissionService.init()
            .subscribe(() => {
                if (this.submissionService.submissionAvailable) {
                    this.navigateToSurvey();
                }
            });
        this.route.queryParams.subscribe(params => {
            this.referrer = params['ref'];
        });
    }

    private navigateToSurvey() {
        const surveyPage = '/survey';
        console.log('redirect to survey: ', surveyPage);
        this.router.navigate([surveyPage], {queryParamsHandling: 'merge'});
    }

    private validatForm(): void {
        this.validAge = !this.age || this.isValidAge();
    }

    private isValidAge(): boolean {
        return this.age > 1900 && this.age < new Date().getFullYear() + 1;
    }

    public submit() {

        if (this.SHOW_USER_INFO) {
            this.validatForm();

            if (this.validAge) {
                this.submissionService.initSubmission(this.age ? this.age : 0, this.gender, this.referrer)
                    .then(() => this.router.navigate(['/survey']));
            }
        } else {
            this.submissionService.initSimpleSubmission(this.referrer)
                .then(() => this.router.navigate(['/survey']));
        }
    }

}
