import Vote from './vote';
import {ImageDto} from './dtos-types';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../services/storage.service';
import {mergeMap} from 'rxjs/operators';
import Dataset from './dataset';
import {ImageRating} from './imageRating';
import {ImageFirestoreService} from '../services/image-firestore.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import * as uuid from 'uuid';
import {environment} from '../../../environments/environment';
import ICSVRow from './ICSVRow';

export enum IMAGE_CATEGORY {
  CHAIR, TABLE, SIDEBOARD
}

export class Image implements ICSVRow {

  constructor(public key: string = null,
              private url: string,
              private html: string,
              readonly additionalImageUrls: string[] = [],
              public category: IMAGE_CATEGORY = null,
              public source: string = null,
              public hidden: boolean = null,
              public orderIndex: number = null,
              public image: HTMLImageElement = null
              ) {
    this.orderIndex = this.orderIndex || null; // prevent orderIndex to be undefined
    this.source = this.source || null; // prevent source to be undefined
    this.hidden = this.hidden === undefined || this.hidden === null ? true : this.hidden;
    this.image = document.createElement('img');
  }

  public toDto(): ImageDto {
    return {
      url: this.url,
      html: this.html,
      orderIndex: this.orderIndex,
      category: IMAGE_CATEGORY[this.category],
      source: this.source,
      hidden: this.hidden,
      additionalImageUrls: this.additionalImageUrls
    };
  }

  public preloadImage(): void {
    this.image.src = this.imageUrl;
  }

  public get imageUrl(): string {
    return this.key ? environment.storageBaseUrl + this.key : null;
  }

  public get htmlSnipped(): string {
    return this.html;
  }

  public get urlHostname() {
    let hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (this.url.indexOf('//') > -1) {
      hostname = this.url.split('/')[2];
    } else {
      hostname = this.url.split('/')[0];
    }

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
  }

  public static deleteByKey(key: string, imageFirestoreService: ImageFirestoreService, storageService: StorageService): Observable<void> {
    const promises = [];
    console.log('delete image', key);
    promises.push(imageFirestoreService.remove(key));
    promises.push(storageService.remove(key));
    return fromPromise(Promise.all(promises).then(_ => console.log('image deleted from storage and firestore')));
  }

  public static ofUrl(url: string, category: IMAGE_CATEGORY, source: string): Image {
    return new Image(uuid.v4(), url, this.defaultHtml(url), [], category, source, true);
  }

  static ofImages(key: string, mainImageUrl: string, additionalImageUrls: string[], category: IMAGE_CATEGORY, source: string) {
    return new Image(key, mainImageUrl, this.defaultHtml(mainImageUrl), additionalImageUrls, category, source, true);
  }

  private static defaultHtml(url: string): string {
    return `<img src="${url}"></img>`;
  }

  public static of(url: string, html: string, orderIndex: number, key: string = null, category: string = null, source: string = null, hidden: boolean = null, additionalImageUrls: string[] = []) {
    return new Image(key, url, html, additionalImageUrls, category ? IMAGE_CATEGORY[category.toUpperCase()] : null, source, hidden, orderIndex);
  }

  public static ofDtos(dtos: ImageDto[]): Image[] {
    return dtos.map(dto => this.of(dto.url, dto.html, dto.orderIndex, dto.key, dto.category, dto.source, dto.hidden, dto.additionalImageUrls));
  }

  public static orderByAvgRate(imgs: Image[], dataset: Dataset): Image[] {
    const start = Date.now();
    console.log('start sorting');
    const imageRatings = dataset.toImageRatings();
    console.log('img Rating calculated', (Date.now() - start), 'ms');
    const sortedImages = imgs.sort((a, b) => {
      return ImageRating.find(imageRatings, b).avgRating - ImageRating.find(imageRatings, a).avgRating;
    });
    console.log('sorting end in ', (Date.now() - start), 'ms');
    return sortedImages;
  }

  public static orderByOrderKey(images: Image[]): Image[] {
    images.sort((img1, img2) => img1.orderIndex - img2.orderIndex);
    return images;
  }

  /**
   * returns a list of images in chunks of the different categories
   * (for example with chunk size 20: [20x Chairs, 20x Table, 20x Sideboard, 20x Chair ....])
   * keeps same order as before within a category
   **/
  public static groupIntoCategoryChunks(images: Image[], chunkSize: number, categoryOrder: IMAGE_CATEGORY[]): Image[] {
    const result = [];

    // group images by category
    const imagesByCategory: Image[][] = [];
    categoryOrder.forEach((category: IMAGE_CATEGORY) => {
      const categoryImages = images.filter(img => img.category === category);
      imagesByCategory.push(categoryImages);
    });

    while (imagesByCategory.some(categoryImages => categoryImages.length > 0)) {
      imagesByCategory.forEach((categoryImages: Image[]) => {
        categoryImages.splice(0, chunkSize)
          .forEach(image => result.push(image));
      });
    }

    return result;
  }

  static findAll(imageService: ImageFirestoreService): Observable<Image[]> {
    return imageService.findAll();
  }

  private static saveImage(key: string, image: Blob, storageService: StorageService): Promise<string> {
    return storageService.saveBlob(key, image).then(_ => key);
  }

  public hasKey(other: string) {
    return this.key === other;
  }

  public vote(vote: number): Vote {
    return Vote.of(this.key, this.category, vote);
  }

  public delete(imageFirestoreService: ImageFirestoreService, storageService: StorageService): Observable<void> {
    return Image.deleteByKey(this.key, imageFirestoreService, storageService);
  }

  public save(imageFirestoreService: ImageFirestoreService, httpClient: HttpClient, storageService: StorageService): Observable<string> {
    return imageFirestoreService.existsByUrl(this.url)
      .pipe(
        mergeMap(exists => {
            if (!exists) {
              return Image.loadImage(this.url, httpClient).pipe(
                mergeMap((imageBlob: Blob) => {
                  if (!imageBlob) {
                    return undefined;
                  }
                  return imageFirestoreService.save(this).then(key => {
                    return Image.saveImage(key, imageBlob, storageService)
                      .catch(err => {
                        console.error('failes to upload image file to firebase storage. Will delete image entry in firestore to prevent inconsistency', err);
                        return imageFirestoreService.remove(key);
                      });
                  });
                }));
            } else {
              console.log('image already exists!', this.url);
              return new Observable<string>(observer => observer.complete());
            }
          }
        )
      );
  }

  public static loadImage(url: string, httpClient: HttpClient): Observable<Blob> {
    return httpClient.get(url, {responseType: 'blob'});
  }

  fromHouzz() {
    return this.source === 'houzz';
  }

  additionalImageUrl(number: number): string {
    return environment.storageBaseUrl + this.key + '_' + number + '?alt=media';
  }

  approve(): Image {
    this.hidden = false;
    return this;
  }

  toCSVArray(): string[] {
    return [this.key, IMAGE_CATEGORY[this.category], this.source, this.url, this.additionalImageUrls.join(' ')];
  }
}
