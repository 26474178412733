export class ImageProvider {
  constructor(private name: string,
              public website: string) {
  }

  public static findBySource(source: string) {
    return ALL_PROVIDERS.find((provider: ImageProvider) => provider.name.toLowerCase() === source.toLowerCase());
  }
}


export const GALAXUS: ImageProvider = new ImageProvider('galaxus', 'galaxus.ch');
export const IKEA: ImageProvider = new ImageProvider('ikea', 'ikea.ch');
export const HOUZZ: ImageProvider = new ImageProvider('houzz', 'houzz.com');
export const PFISTER: ImageProvider = new ImageProvider('pfister', 'pfister.ch');

const ALL_PROVIDERS: ImageProvider[] = [GALAXUS, IKEA, HOUZZ, PFISTER];

