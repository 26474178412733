import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SubmissionKeeper} from '../shared/services/submission-keeper.service';
import {Image} from '../shared/model/image';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageFirestoreService} from '../shared/services/image-firestore.service';
import {StorageService} from '../shared/services/storage.service';
import {TranslateService} from '../translate.service';
import {Subscription} from 'rxjs';
import {Round} from '../shared/model/round';
import {AuthService} from '../auth/auth.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NoInternetDialogComponent} from './no-internet-dialog/no-internet-dialog.component';
import {first} from 'rxjs/operators';
import { ShareService } from '@ngx-share/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';

const MOTIVATIONAL_WORDS: String[] = [];
for (let i = 1; i <= 15; i++) {
  MOTIVATIONAL_WORDS.push('motivationalWord' + i);
}

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyComponent implements OnInit, OnDestroy {

  public round: Round;
  public loading = true;
  public motivationalWords: string[] = [];

  public showMotivationWord = false;
  public motivationWordIndex = 0;
  public allImagesRated = false;

  public loadingTime = 0;
  private imageSubscription: Subscription;
  private image: Image;
  showAdminButtons = false;
  private noInternetDialogRef: MatDialogRef<NoInternetDialogComponent, any>;
  public faFacebookF = faFacebookF;
  public faTwitter = faTwitter;
  public faLinkedinIn = faLinkedinIn;
  public faWhatsapp = faWhatsapp;

  private readonly SURVEY_CIRCLE_CODE = '9CJK-31YH-Y4QH-Q28G';

  constructor(private submissionService: SubmissionKeeper,
              private route: ActivatedRoute,
              private router: Router,
              private imageFirestoreService: ImageFirestoreService,
              private storageService: StorageService,
              private translateSerivce: TranslateService,
              private authService: AuthService,
              private matDialog: MatDialog,
              public share: ShareService) {
  }

  ngOnInit(): void {

    this.submissionService.init()
      .subscribe(() => {
        if (!this.submissionService.submissionAvailable) {
          this.navigateToUserInfo();
        } else {
          this.loadNextRound();

          this.initMotivationWords();
        }
      });

    this.showAdminButtons = this.authService.isLoggedIn;
  }

  ngOnDestroy(): void {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }

  }

  private navigateToUserInfo() {
    const userInfoPage = '/';
    console.log('redirect to userinfo: ', userInfoPage);
    this.router.navigate([userInfoPage], {queryParamsHandling: 'merge'});
  }

  private initMotivationWords() {
    let found = true;
    let index = 1;
    while (found) {
      const motivationWord = this.translateSerivce.data[`motivationalWord${index++}`];
      if (motivationWord) {
        this.motivationalWords.push(motivationWord);
      }
      found = !!motivationWord;
    }
  }

  public get isFirstRound(): boolean {
    return this.motivationWordIndex === 0;
  }

  private get activeRoundNumber(): number {
    return this.round.roundNumber;
  }

  public startNextRound() {
    this.showMotivationWord = false;
  }

  private loadNextRound() {
    this.loading = true;
    this.submissionService.nextRound()
      .pipe(
        first()
      )
      .subscribe(round => {
        console.log('round=', round);

        this.round = round;
        this.loading = false;

        if (!round.hasNextImage()) {
          this.allImagesRated = true;
          return;
        }

        this.round.preloadImages();
        this.image = this.nextImage();
      });
  }


  public get progressBarStatus(): number {
    return this.round.progress;
  }

  public get motivationalWord() {
    return MOTIVATIONAL_WORDS[this.motivationWordIndex];
  }

  public rate(vote: number) {
    const ratedImage = this.image;
    console.log('rate image as ', vote, ' - image:', ratedImage);
    this.submissionService.addVote(ratedImage, vote);
    this.image = this.nextImage();
  }

  onImageLoadingError() {
    console.log('error while trying to load image. Skip image and show next');
    if (!this.noInternetDialogRef) {
      this.noInternetDialogRef = this.matDialog.open(NoInternetDialogComponent, {
        width: '250px',
      });
      this.noInternetDialogRef.afterClosed()
        .subscribe(_ => {
          this.image = this.nextImage();
          this.noInternetDialogRef = null;
        });
    }
  }

  public delete() {
    this.image.delete(this.imageFirestoreService, this.storageService);
    this.image = this.nextImage();
  }

  public removeSubmission() {
    this.submissionService.remove();
    window.location.reload();
  }

  private nextImage(): Image {
    if (this.round.hasNextImage()) {
      const image: Image = this.round.nextImage();
      if (this.submissionService.hasVotedImage(image)) {
        console.log('image has already been voted! Will be skipped!');
        return this.nextImage();
      } else {
        return image;
      }
    } else {
      this.motivationWordIndex = (this.activeRoundNumber - 1) % MOTIVATIONAL_WORDS.length;
      this.showMotivationWord = true;
      this.submissionService.save();
      this.loadNextRound();
    }
  }

  showSurveyCircleCode(): boolean {
    return  this.submissionService.isSurveyCircle() && this.motivationWordIndex > 0;
  }
}
