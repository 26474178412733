import { Injectable } from '@angular/core';
import de from '../assets/i18n/de.json';
import en from '../assets/i18n/en.json';
@Injectable()
export class TranslateService {
  data: any = {};
  constructor() {
  }
  use(lang: string): void {
    if(lang === "en"){
      this.data = en;
    } else {
      this.data = de;
    }
  }
}
