import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Image} from '../../shared/model/image';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-inner-html',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.css']
})
export class InnerHtmlComponent implements OnChanges, AfterViewInit {

  @Input()
  public image: Image;

  @Output()
  private onLoaded: EventEmitter<void> = new EventEmitter();

  @Output()
  private imageLoadingError = new EventEmitter<void>();

  public loading = false;
  baseImageUrl = environment.storageBaseUrl;

  @ViewChild('wrapper')
  wrapper: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnChanges() {
    if (!this.image) {
      return;
    }
    this.loading = true;
    // wait until html snipped was injected to html and <img> tag is available in dom
    setTimeout(() => this.setImageEventListener(), 0);
  }

  ngAfterViewInit(): void {
    this.setImageEventListener();
  }

  setImageEventListener() {
    const htmlElements = this.wrapper.nativeElement.querySelectorAll('img');

    for (let i = 0; i < htmlElements.length; i++) {
      if (htmlElements[i].complete) {
        this.onImageLoaded();
      }
      htmlElements[i].addEventListener('error', err => this.onImageLoadingError());
      htmlElements[i].addEventListener('load', () => this.onImageLoaded());
    }
  }

  onImageLoaded() {
    console.log('image finished loading');
    this.loading = false;
    this.onLoaded.emit();
  }

  private onImageLoadingError() {
    this.loading = false;
    this.imageLoadingError.emit();
  }
}
