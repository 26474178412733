import {Injectable} from '@angular/core';
import {Submission} from '../model/submission';
import {SubmissionFirestoreService} from './submission-firestore.service';
import {Observable, of} from 'rxjs';
import {first} from 'rxjs/operators';
import {Image} from '../model/image';
import {HttpClient} from '@angular/common/http';
import {ImageFirestoreService} from './image-firestore.service';
import {Round} from '../model/round';
import {StatisticFirestoreService} from './statistic-firestore.service';
import {environment} from '../../../environments/environment';
import {config} from '../../../environments/config';

const LOCAL_STORAGE_KEY = 'occasi-submission-key' + (environment.production ? '' : '-test') + (config.client ? '-' + config.client : '');

@Injectable({
  providedIn: 'root'
})
export class SubmissionKeeper {
  private readonly SAVE_AFTER_NR_OF_VOTES: number = 3;

  private voteCounter = 0;
  private submission: Submission = undefined;

  constructor(private submissionFirestoreService: SubmissionFirestoreService,
              private imageFirestoreService: ImageFirestoreService,
              private statisticFirestoreService: StatisticFirestoreService,
              private http: HttpClient) {
    this.init();
  }

  public init(): Observable<any> {
    const submissionKey = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!submissionKey || this.submission) {
      return of(undefined);
    }

    const $submission = this.submissionFirestoreService.find(submissionKey).pipe(first());
    $submission.subscribe(submission => this.submission = submission);
    return $submission;
  }

  public initSubmission(age: number, gender: string, referrer: string): PromiseLike<any> {
    this.submission = Submission.of(age, gender, referrer, config.client);
    return this.submission.save(this.submissionFirestoreService)
      .then(id => this.saveSubmissionKey(id));
  }

  public initSimpleSubmission(referrer: string): PromiseLike<any> {
    return this.initSubmission(0, '', referrer);
  }

  public get submissionAvailable(): boolean {
    return !!this.submission;
  }

  public hasVotedImage(image: Image): boolean {
    return this.submission.hasVotedImage(image);
  }

  public nextRound(): Observable<Round> {
    return this.submission.nextRound(this.imageFirestoreService, this.statisticFirestoreService);
  }

  public get ratedImagesCount(): number {
    return this.submission.ratedImagesCount;
  }

  public addVote(image: Image, vote: number) {
    this.voteCounter++;
    this.submission.addVote(image.vote(vote));
    if (this.voteCounter % this.SAVE_AFTER_NR_OF_VOTES === 0) {
      this.save();
    }
  }

  public save() {
    this.submission.save(this.submissionFirestoreService);
  }

  public remove() {
    this.submission.remove(this.submissionFirestoreService);
    this.submission = undefined;
    this.removeSubmissionKey();
  }

  private removeSubmissionKey() {
    localStorage.clear();
  }

  private saveSubmissionKey(submissionKey) {
    console.log('save submission key ', submissionKey, ' to localstorage as ', LOCAL_STORAGE_KEY);
    localStorage.setItem(LOCAL_STORAGE_KEY, submissionKey);
  }

  isSurveyCircle(): boolean {
    return this.submission.isSurveyCircle();
  }
}
