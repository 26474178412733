import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-no-internet-dialog',
  templateUrl: './no-internet-dialog.component.html',
  styleUrls: ['./no-internet-dialog.component.css']
})
export class NoInternetDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<NoInternetDialogComponent>) {
  }

  retry() {
    this.dialogRef.close();
  }
}
