import {ImageRating} from './imageRating';
import ICSVRow from './ICSVRow';

const MAX_RANDOM_RATING = 2;
const MIN_RANDOM_RATING = 1;

export class Rating implements ICSVRow {
  private readonly user: string;
  private readonly item: string;
  private readonly rate: number;
  private readonly timestamp: number;

  private constructor(user: string, item: string, rate: number, timestamp: number) {
    this.user = user;
    this.item = item;
    this.rate = rate;
    this.timestamp = timestamp;
  }

  public static of(user: string, item: string, rate: number, timestamp: number) {
    return new Rating(user, item, rate, timestamp);
  }

  public toCSVArray(): any[] {
    return [this.user, this.item, this.ratingInCsvRange, this.timestamp];
  }

  /**
   * Maps the original rating to a range between [-1,1]
   */
  private get ratingInCsvRange() {
    if (this.rate === 2) {
      return 1;
    }
    return this.rate === 1 ? 0 : -1;
  }

  public appendImageRatingTo(imageRatings: ImageRating[]): ImageRating[] {
    const availableRatingIndex = imageRatings.findIndex(imageRating => imageRating.forImage(this.item));
    if (availableRatingIndex !== -1) {
      imageRatings[availableRatingIndex] = imageRatings[availableRatingIndex].append(this.rate);
    }
    if (availableRatingIndex === -1) {
      imageRatings.push(ImageRating.empty(this.item));
    }
    return imageRatings;
  }

  public static randomRating(): number {
    return Math.floor(Math.random() * MAX_RANDOM_RATING) + MIN_RANDOM_RATING;
  }
}
