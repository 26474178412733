import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SurveyComponent} from './survey/survey.component';
import {UserInfoComponent} from './survey/user-info/user-info.component';
import {LoginComponent} from './survey/login/login.component';
import {AuthGuard} from './shared/guard/auth.guard';

const routes: Routes = [
  {path: '', component: UserInfoComponent},
  {path: 'survey', component: SurveyComponent},
  {path: 'login', component: LoginComponent},
  { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule) },
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) }];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {
}


