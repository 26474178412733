import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SurveyComponent} from './survey.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {InnerHtmlComponent} from './inner-html/inner-html.component';
import {ImageRaterComponent} from './image-rater/image-rater.component';
import {TranslatePipe} from './translate.pipe';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import { SurveyImageComponent } from './survey-image/survey-image.component';
import {MatDialogModule} from '@angular/material/dialog';
import { NoInternetDialogComponent } from './no-internet-dialog/no-internet-dialog.component';
import { ShareButtonsConfig, ShareModule } from '@ngx-share/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const customConfig: ShareButtonsConfig = {
  autoSetMeta: true,
  twitterAccount: 'InnoFind_ch'
};

@NgModule({
  declarations: [
    SurveyComponent,
    UserInfoComponent,
    InnerHtmlComponent,
    ImageRaterComponent,
    TranslatePipe,
    LoginComponent,
    SurveyImageComponent,
    NoInternetDialogComponent
  ],
  exports: [
    InnerHtmlComponent,
    SurveyImageComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatProgressBarModule,
    MatSelectModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatDialogModule,
    ShareModule.withConfig(customConfig),
    FontAwesomeModule]
})
export class SurveyModule {
}
