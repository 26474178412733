import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Image} from '../../shared/model/image';

@Component({
  selector: 'app-image-rater',
  templateUrl: './image-rater.component.html',
  styleUrls: ['./image-rater.component.css']
})
export class ImageRaterComponent {

  @Input() image: Image;

  @Output() onRate: EventEmitter<number> = new EventEmitter();
  @Output() imageLoadingError = new EventEmitter<void>();

  public loading = true;

  public imageLoaded() {
    console.log('image loaded');
    this.loading = false;
  }

  public rate(vote: number) {
    console.log('rated', vote);
    this.onRate.emit(vote);
  }

  onImageLoadingError() {
    this.imageLoadingError.emit();
  }
}
