import {Image} from './image';

export class Round {

  private index = 0;

  constructor(private images: Image[], public roundNumber: number) {
    console.log('new round, roundnumber: ', roundNumber, ', anzahl Bilder in Round: ', images.length);
  }

  public preloadImages(): void{
    this.images.forEach(image => image.preloadImage());
  }

  nextImage(): Image {
    return this.images[this.index++];
  }

  public get progress(): number {
    return (this.index / this.images.length) * 100;
  }

  public hasNextImage(): boolean {
    return this.index < this.images.length;
  }
}
