import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, Query} from '@angular/fire/firestore';
import {first, map} from 'rxjs/operators';
import {Image, IMAGE_CATEGORY} from '../model/image';
import {AngularFirestoreCollection} from '@angular/fire/firestore/collection/collection';
import {ImageDto} from '../model/dtos-types';
import {environment} from '../../../environments/environment';
import {config} from '../../../environments/config';

@Injectable({
  providedIn: 'root'
})
export class ImageFirestoreService {

  private readonly TABLE_NAME = environment.imagesTable;

  private list: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) {
    this.list = db.collection(this.TABLE_NAME, ref => this.clientFilter(ref));
  }

  public findAll(): Observable<Image[]> {
    return this.list.snapshotChanges().pipe(
      first(),
      map(changes => {
        return changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
      }),
      map((dtos: ImageDto[]) => Image.ofDtos(dtos))
    );
  }

  public findAllVisible(): Observable<Image[]> {
    return this.db.collection(this.TABLE_NAME, ref =>
      this.clientFilter(ref)
        .where('hidden', '==', false)
    )
      .snapshotChanges()
      .pipe(
        first(),
        map((changes: any[]) => {
          return changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
        }),
        map((dtos: ImageDto[]) => Image.ofDtos(dtos))
      );
  }

  public findVisibleByCategory(category: IMAGE_CATEGORY): Observable<Image[]> {
    console.log('load all images for category ', IMAGE_CATEGORY[category]);
    return this.db.collection(this.TABLE_NAME, ref =>
      this.clientFilter(ref)
        .where('category', '==', IMAGE_CATEGORY[category])
        .where('hidden', '==', false)
    )
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}))),
        map((dtos: ImageDto[]) => Image.ofDtos(dtos))
      );
  }

  findHiddenImages(limit: number): Observable<Image[]> {
    return this.db.collection(this.TABLE_NAME, ref =>
      this.clientFilter(ref)
        .where('hidden', '==', true)
        .limit(limit)
    )
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
        }),
        map((dtos: ImageDto[]) => Image.ofDtos(dtos))
      );
  }

  findVisibleImages(category: IMAGE_CATEGORY, startAt: number, limit: number): Observable<Image[]> {
    console.log('findImages with following params.. category:', IMAGE_CATEGORY[category], ', startAt:', startAt, ', limit:', limit);
    return this.db.collection(this.TABLE_NAME, ref =>
      this.clientFilter(ref)
        .where('category', '==', IMAGE_CATEGORY[category])
        .where('hidden', '==', false)
        .orderBy('orderIndex')
        .startAt(startAt)
        .limit(limit)
    ).snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes.map(c => ({key: c.payload.doc.id, ...c.payload.doc.data()}));
        }),
        map((dtos: ImageDto[]) => Image.ofDtos(dtos))
        // tap(imgs => console.log('found category', IMAGE_CATEGORY[category], 'startat', startAt, imgs))
      );
  }

  public save(image: Image): Promise<string> {
    console.log('save image ', image.key);
    if (image.key) {
      return this.update(image);
    }
    return this.insert(image);
  }

  public existsByUrl(url: string): Observable<boolean> {
    return this.db.collection(this.TABLE_NAME, ref => ref.where('url', '==', url)).snapshotChanges().pipe(
      map(querySnapshot => {
        return querySnapshot.length > 0;
      }));
  }

  public updateAll(images: Image[]): Promise<void> {
    const imagesCopy = Array.from(images);
    const promises = [];
    while (imagesCopy.length > 0) {

      const batch = this.db.firestore.batch();
      const imagesChunk = imagesCopy.splice(0, 500);

      imagesChunk.forEach((image, index) => {
        const ref = this.list.doc(image.key).ref;
        batch.update(ref, image.toDto());
      });

      promises.push(batch.commit());
    }
    return Promise.all(promises).then(_ => undefined);
  }

  private update(image: Image): Promise<string> {
    console.log('update image on firebase ', image.key);
    return this.db
      .doc(this.TABLE_NAME + '/' + image.key)
      .set(image.toDto())
      .then(_ => image.key);
  }

  private insert(image: Image): Promise<string> {
    console.log('save new image', image);
    return this.db.collection('images')
      .add(image.toDto())
      .then(ref => ref.id);
  }

  public exists(key: string): Observable<boolean> {
    //TODO
    return new Observable<boolean>(it => it.next(true));
  }

  public remove(key: string): Promise<string> {
    return this.db.doc(this.TABLE_NAME + '/' + key)
      .delete()
      .then(_ => key);
  }

  private clientFilter(ref: Query): Query {
    if (config.client) {
      return ref.where('source', '==', config.client);
    }
    return ref;
  }
}
